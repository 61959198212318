// @flow

import React from "react";
import {
    AssignmentLayout,
    Layout,
    withPrivateRoute,
    Attachments,
} from "@containers";

/**
 * Bijlagen
 */
const Bijlagen = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Bijlagen">
            <Attachments title="Bijlagen" />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(Bijlagen, "/opdrachten/:inspectionId/bijlagen");
